<template>
  <div class="relative">
    <div>
      <LayoutNavBar />
      <slot />
      <LayoutFooter />
      <LayoutBackToTop :scy="scY" />
      <UNotifications />
    </div>
  </div>
</template>

<script type="ts" setup>
import { ref, onMounted } from 'vue'

const scTimer = ref(0 | null)
const scY = ref(0)

onMounted(() => {
  window.addEventListener('scroll', () => {
    clearTimeout(scTimer.value)
    scTimer.value = setTimeout(() => {
      scY.value = window.scrollY
    }, 10)
  })
})
</script>
