<template>
  <div class="bg-primary pr-10 sm:pr-16">
    <div class="container flex flex-col justify-between py-6 sm:flex-row">
      <div>
        <p class="text-center text-white font-body md:text-left">
          © Copyright 2024. All right reserved, Daniel Hagen.
        </p>
        <p class="text-center text-white font-body md:text-left">
          Built {{ appConfig.buildDate }}
        </p>
      </div>
      <div class="flex items-center justify-center pt-5 sm:justify-start sm:pt-0">
        <AuthorSocials
          class="text-white hover:text-highlight"
          :socials="(appConfig.socials as Social[])"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Social } from '~/components/AuthorSocials.vue'

const appConfig = useAppConfig()
</script>
